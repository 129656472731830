/**
 * @file
 * Faceted Filters - Adds Faceted Filters Block functionality.
 */
(function($) {

  window.cached_data = {};

  'use strict';

  $(window).on('load', function() {
    setTimeout(function() {
      parseFilters();
      initializeDropdown();
    }, 250);
  });

  var maxWidth = 345;
  var maxHeight = 194;
  var widthwindows = $(document).width();

  if (widthwindows > 375 && widthwindows < 768) {
    var sumWidth = widthwindows - 375;
    maxWidth = maxWidth + sumWidth;
    maxHeight = Math.round((194 * maxWidth) / 345);
    maxWidth = maxWidth + 'px';
    maxHeight = maxHeight + 'px';
  }

  if (widthwindows >= 768) {
    maxWidth = '95%';
    maxHeight = '85%';
  }

  var cboxOptions = {
    iframe: true,
    width: '95%',
    height: '85%',
    maxWidth: maxWidth,
    maxHeight: maxHeight,
    fixed: true,
    closeButton: true
  };

  function parseFilters() {
    $(document).find('.faceted-filter-block .filters-section .tab-content .tab-pane .filter-item').each(function(index, item) {
      var name = $.trim($(item).find('.filter-name').text());
      $(item).find('.filter-name').text(name.replace(new RegExp('_', 'g'), ' '));
    });
  }

  /*
   * Updates source for a youtube video.
   */
  function youtube_embed(url) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[7].length == 11) {
      return 'https://www.youtube.com/embed/' + match[7];
    } else {
      return url;
    }
  }

  /*
   * Updates query of url.
   */
  function updateQueryStringParameter(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
      return uri + separator + key + "=" + value;
    }
  }

  function initializeDropdown() {
    $(document).find('.faceted-filter-block').each(function() {
      var dropdown = $(this).find('.facets-results .date-order select');
      $(dropdown).select2({
        dropdownAutoWidth: false,
        closeOnSelect: true,
        minimumResultsForSearch: Infinity,
        dropdownCssClass: 'order-dates-dropdown',
        width: '160px',
        debug: true
      });

      $(dropdown).val($(dropdown).find('option:selected').val())

      $(dropdown).on('select2:selecting', function(e) {
        if ($(this).closest('.faceted-filter-block').hasClass('use-ajax')) {
          window.history.pushState({}, null, replaceUrlParam(window.location.href, 'sort', e.params.args.data.id));
          const facetedFilter = $(this).closest('.faceted-filter-block');
          const typeOfDocument = $(this).closest('.faceted-filter-block').attr('data-document');
          const mainCategory = urlEncode($(this).closest('.faceted-filter-block').attr('data-main-category'));
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const query = urlParams.get('query') ?? '';
          const sort = urlParams.get('sort') ?? 'desc';
          const officeDept = urlParams.get('officeDept') ?? '';
          const docType = urlParams.get('docType') ?? '';
          const subject = urlParams.get('subject') ?? '';
          const dateStart = urlParams.get('dateStart') ?? '';
          const dateEnd = urlParams.get('dateEnd') ?? '';
          const page = urlParams.get('page') ?? 0;
          const letter = urlParams.get('letter') ?? '';

          let cached_data = window.cached_data['/umbraco/Api/FacetedFilter/GexMixedContent?documentType=' + typeOfDocument
            + '&mainCategory=' + mainCategory
            + '&letter=' + letter
            + '&query=' + query
            + '&officeDept=' + officeDept
            + '&docType=' + docType
            + '&subject=' + subject
            + '&dateStart=' + dateStart
            + '&dateEnd=' + dateEnd
            + '&page=' + page
            + '&sort=' + sort];

          if (cached_data) {
            renderContent(cached_data, facetedFilter, typeOfDocument, mainCategory);
            return;
          }

          getData('/umbraco/Api/FacetedFilter/GexMixedContent?documentType=' + typeOfDocument
            + '&mainCategory=' + mainCategory
            + '&letter=' + letter
            + '&query=' + query
            + '&officeDept=' + officeDept
            + '&docType=' + docType
            + '&subject=' + subject
            + '&dateStart=' + dateStart
            + '&dateEnd=' + dateEnd
            + '&page=' + page
            + '&sort=' + sort).then(function(res) {
              window.cached_data['/umbraco/Api/FacetedFilter/GexMixedContent?documentType=' + typeOfDocument
                + '&mainCategory=' + mainCategory
                + '&letter=' + letter
                + '&query=' + query
                + '&officeDept=' + officeDept
                + '&docType=' + docType
                + '&subject=' + subject
                + '&dateStart=' + dateStart
                + '&dateEnd=' + dateEnd
                + '&page=' + page
                + '&sort=' + sort] = res;
              renderContent(res, facetedFilter, typeOfDocument, mainCategory);
            });
        } else {
          var newUrl = updateQueryStringParameter(window.location.href, 'sort', e.params.args.data.id);
          window.location = newUrl;
        }
      });

      var arrowIcon = '<span class="material-icons notranslate">expand_more</span>';

      $(this).find('.facets-results .date-order .select2 .selection .select2-selection').append(arrowIcon);
    });
  }

  $(document).on('click', '.global-search-pagination .t-pagination  button', function() {
    if ($(this).parent().hasClass('active')) {
      var parentElement = $(this).closest('.faceted-filter-block').offset().top - 60;

      $('html, body').animate({
        scrollTop: parentElement
      }, 600);

      return false;
    }

    if (!$(this).hasClass('disabled')) {
      var newUrl = updateQueryStringParameter(window.location.href, 'page', $(this).attr('data-href'));

      if ($(this).closest('.faceted-filter-block').hasClass('use-ajax')) {
        var parentElement = $(this).closest('.faceted-filter-block').offset().top - 60;

        $('html, body').animate({
          scrollTop: parentElement
        }, 600);

        window.history.pushState({}, null, replaceUrlParam(window.location.href, 'page', urlEncode($(this).attr('data-href'))));
        const facetedFilter = $(this).closest('.faceted-filter-block');
        const typeOfDocument = $(this).closest('.faceted-filter-block').attr('data-document');
        const mainCategory = urlEncode($(this).closest('.faceted-filter-block').attr('data-main-category'));
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const query = urlParams.get('query') ?? '';
        const sort = urlParams.get('sort') ?? 'desc';
        const officeDept = urlParams.get('officeDept') ?? '';
        const docType = urlParams.get('docType') ?? '';
        const subject = urlParams.get('subject') ?? '';
        const dateStart = urlParams.get('dateStart') ?? '';
        const dateEnd = urlParams.get('dateEnd') ?? '';
        const page = urlParams.get('page') ?? 0;
        const letter = urlParams.get('letter') ?? '';

        let cached_data = window.cached_data['/umbraco/Api/FacetedFilter/GexMixedContent?documentType=' + typeOfDocument
          + '&mainCategory=' + mainCategory
          + '&letter=' + letter
          + '&query=' + query
          + '&officeDept=' + officeDept
          + '&docType=' + docType
          + '&subject=' + subject
          + '&dateStart=' + dateStart
          + '&dateEnd=' + dateEnd
          + '&page=' + page
          + '&sort=' + sort];

        if (cached_data) {
          renderContent(cached_data, facetedFilter, typeOfDocument, mainCategory);
          return;
        }

        getData('/umbraco/Api/FacetedFilter/GexMixedContent?documentType=' + typeOfDocument
          + '&mainCategory=' + mainCategory
          + '&letter=' + letter
          + '&query=' + query
          + '&officeDept=' + officeDept
          + '&docType=' + docType
          + '&subject=' + subject
          + '&dateStart=' + dateStart
          + '&dateEnd=' + dateEnd
          + '&page=' + page
          + '&sort=' + sort).then(function(res) {
            window.cached_data['/umbraco/Api/FacetedFilter/GexMixedContent?documentType=' + typeOfDocument
              + '&mainCategory=' + mainCategory
              + '&letter=' + letter
              + '&query=' + query
              + '&officeDept=' + officeDept
              + '&docType=' + docType
              + '&subject=' + subject
              + '&dateStart=' + dateStart
              + '&dateEnd=' + dateEnd
              + '&page=' + page
              + '&sort=' + sort] = res;
            renderContent(res, facetedFilter, typeOfDocument, mainCategory);
          });
      } else {
        window.location = newUrl;
      }
    }
  });

  function getData(ajaxurl) {
    return Promise.resolve($.ajax({
      url: ajaxurl,
      type: 'GET',
    }));
  };

  function renderContent(data, facetedFilter, typeOfDocument, mainCategory) {
    var counter = 0;
    var defaultImage = '/assets/images/default-documents-image.jpg';
    var items = data.items;
    var pages = data.pages;
    var currentPage = data.currentPage;
    var maxElements = data.maxElements;
    var FacetsOfficeDept = data.facetsOfficeDept;
    var FacetsDocType = data.facetsDocType;
    var FacetsSubjects = data.facetsSubjects;
    var charName = "";
    var cdnUrl = $('html').attr('data-cdn-url') !== "" ?
      $('html').attr('data-cdn-url') : "";

    $(facetedFilter).find('.facets-results .results .result-item').remove();
    $(facetedFilter).find('.facets-results .results .wysiwyg-field').remove();
    $(facetedFilter).find('.facets-results .results .content').remove();
    $(facetedFilter).find('.facets-results .results .block-image-text').remove();
    var template = "";

    items.forEach(function(item, index) {
      var positionItem = index % 2 == 1;
      var classImage = positionItem ? "has-image" : "";

      switch (typeOfDocument) {
        case 'video':
          var imageVideo = "";

          if (item.image == "") {
            imageVideo = "/assets/images/blog-post-img_mobile.jpg";
          } else {
            imageVideo = item.image;
          }

          template += '<div class="block-image-text col-md-6 col-lg-4 video-type">';
          template += '<div class="block-image-container">';
          template += '<div class="video-img-container">';
          template += '<div class="video-image">';
          template += `<a class="video-selector-iframe cboxElement" href="${item.url}">`;
          template += '<picture>';
          template += `<source srcset="${imageVideo}?mode=crop&width=350&height=198 1x, ${imageVideo}?mode=crop&width=700&height=396 2x" type="image/jpeg" media="all">`;
          template += `<img src="${imageVideo}" class="img-video b-loaded" alt="${item.altImage}">`;
          template += '</picture>';

          template += '<div class="video-btn-container d-flex justify-content-center align-items-center">';
          template += '<picture>';
          template += '<source srcset="/assets/images/button-circle-arrow-default.png 1x, /assets/images/button-circle-arrow-default.png 2x" media="all">';
          template += '<img class="btn-play" src="/assets/images/button-circle-arrow-default.png" alt="arrow button play" loading="lazy">';
          template += '</picture>';
          template += '</div>';
          template += '</a>';
          template += '</div>';
          template += '</div>';

          template += '<div class="main-content-text">';
          template += '<div class="text-content">';
          template += `<p>${item.title}</p>`;
          template += '</div>';

          template += '<div class="date-content">';
          template += `<p>${item.date}</p>`;
          template += '</div>';
          template += '</div>';
          template += '</div>';
          template += '</div>';
          break;
        case 'blog':
          template += '<div class="result-item post col-12">';
          template += '<div class="container-card position-relative d-flex">';
          if (item.image != '') {
            template += '<div class="image position-relative d-none d-md-block">';
            template += '<picture class="img-fluid position-static">';
            template += `<source type="image/jpeg" media="all" srcset="${item.image}?width=350&amp;height=198&amp;quality=80&amp;format=jpg&amp;rnd=132835627001300000">`;
            template += `<img src="${item.image}" class="b-lazy b-loaded" alt="${item.altImage}" typeof="foaf:Image">`;
            template += '</picture>';
            template += '</div>';
          }

          template += '<div class="content w-100">';
          template += `<div class="date h6 text-orange">${item.date}</div>`;
          template += `<div class="title h4">${item.title}</div>`;
          template += '<div class="cta">';
          template += `<a href="${item.url}" class="link-arrow" target="_self">Read More</a>`;
          template += '</div>';
          template += '</div>';
          template += '</div>';
          template += '</div>';
          break;
        case 'job':
          var url = item.url;

          if (charName == "") {
            charName = item.title.substring(0, 1);

            template += `<div class="section-letter">`;
            template += `<h3 class="h3 text-uppercase text-dark notranslate">${charName}</h3>`;
            template += `</div>`;
          }
          else {
            if (charName != item.title.substring(0, 1)) {
              charName = item.title.substring(0, 1);

              template += `<div class="section-letter">`;
              template += `<h3 class="h3 text-uppercase text-dark notranslate">${charName}</h3>`;
              template += `</div>`;
            }
          }

          template += `<div class="l-item internal">`;
          template += `<a data-i="${index}" href="${url}">`;
          template += `<div class="item-content-wrapper">`;
          template += `<div class="content wysiwyg-field">`;
          template += `<div class="item-title h5">${item.title}</div>`;
          template += `</div>`;
          template += `<div class="item-icon">`;
          template += `<i class="material-download"></i>`;
          template += `</div>`;
          template += `</div>`;
          template += `</a>`;
          template += `</div>`;
          break;
        case 'news':
        case 'newsletter':
          template += '<div class="result-item post col-12">';
          template += '<div class="container-card position-relative d-flex">';
          if (item.image != '' && item.image != undefined) {
            template += '<div class="image position-relative d-none d-md-block">';
            template += '<picture class="img-fluid position-static">';
            template += '<source type="image/jpeg" media="all" srcset="' + item.image + '">';
            template += '<img src="' + item.image + '" class="b-loaded" alt="' + item.altImage + '" typeof="foaf:Image">';
            template += '</picture>';
            template += '</div>';
          }

          template += '<div class="content w-100">';
          template += '<div class="categories d-flex flex-row justify-content-between">';
          template += '<div class="title-container text-capitalize h6">';
          template += '<span class="subject-text">';
          if (item.category != undefined && item.category != '') {
            template += item.category;
          }
          template += '</span>';
          template += '</div>';
          template += '</div>';

          template += `<div class="date h6 text-orange">${item.date}</div>`;
          template += `<div class="title h4">${item.title}</div>`;
          template += '<div class="cta">';
          template += `<a href="${item.url}" target="_blank" class="link-arrow">View</a>`;
          template += '</div>';
          template += '</div>';
          template += '</div>';
          template += '</div>';
          break;
        default:
          switch (decodeURI(mainCategory)) {
            case 'Fact Sheets Publications':
              template += `<div class="result-item fact-sheet-and-report cart-content col-12">`;
              template += `<div class="cart-content-text">`;
              if (item.subCat1 != undefined && item.subCat1 != null && item.subCat1 != '') {
                template += `<div class="title">${item.subCat1}</div>`;
              }
              template += `<div class="sup-title">${item.title}</div>`;
              template += `<div class="text-body">${item.description}</div>`;
              template += `<div class="content-btn-download">`;
              template += `<a class="btn-download" href="${cdnUrl + item.url}" target="_blank">View</a>`;
              template += `</div>`;
              template += `</div>`;
              template += `</div>`;
              break;
            case "Newsroom | Press Releases":
            case "Newsroom | Stories":
            case "Newsroom | News":
            case "Newsroom | Newsletters":
            case "Newsroom | Videos":
            case "Newsroom | Blogs":
              var target = '_blank';
              if (item.type != "customFile" && (mainCategory != "Newsroom | News" && mainCategory != "Newsroom | Newsletters" && mainCategory != "Newsroom | Videos")) {
                target = '_self';
              }

              template += '<div class="result-item post col-12" data-item="${index}">';
              template += '<div class="container-card position-relative d-flex">';

              if (item.image != undefined && item.image != '') {
                template += '<div class="image position-relative d-none d-md-block">';
                template += '<picture class="img-fluid position-static">';
                template += `<source type="image/jpeg" media="all" srcset="${item.image}?width=350&amp;height=198&amp;quality=80&amp;format=jpg&amp;rnd=132835627001300000">`;
                template += `<img src="${item.image}" class="b-lazy b-loaded" alt="${item.altImage}" typeof="foaf:Image">`;
                template += '</picture>';
                template += '</div>';
              }

              template += '<div class="content w-100">';
              template += `<div class="date h6 text-orange">${item.date}</div>`;
              template += `<div class="title h4">${item.title}</div>`;
              template += '<div class="cta">';
              template += `<a class="link-arrow" href="${item.url}" data-id="${item.id}" target="${target}">Read More</a>`;
              template += '</div>';
              template += '</div>';
              template += '</div>';
              template += '</div>';
              break;
            default:
              if ($(facetedFilter).find('.facets-results').hasClass('one-column')) {
                template += '<div class="result-item col-12 hide-image ' + classImage + '" data-item="' + index + '" data-document-type="' + mainCategory + '">';
                template += '<div class="container-card position-relative">';
                template += '<div class="categories d-flex flex-row justify-content-between">';
                if (item.subCat1 != null) { template += '<div class="title-container text-capitalize h6 text-orange">' + item.subCat1 + '</div>'; }

                if (item.category != null) {
                  template += '<div class="card-option h6 text-uppercase">';
                  template += '<strong class="subCat1">';
                  template += item.category;
                  template += '</strong>';
                  template += '</div>';
                }

                template += '</div>';

                template += '<div class="content">';
                template += '<div class="title h5">';
                template += item.title;
                template += '</div>';

                template += '<div class="date h6">';
                template += item.date;
                template += '</div>';

                if (item.description != undefined) {
                  template += '<div class="description">';
                  template += item.description;
                  template += '</div>';
                }
                template += '</div>';

                template += '<div class="cta">';
                template += '<a class="link-arrow" href="' + cdnUrl + item.url + '" data-id="' + item.id + '" target="_blank">View Resource</a>';
                template += '</div>';
                template += '</div>';
                template += '</div>';
              } else {
                if (positionItem) {
                  template += '<div class="result-item ' + classImage + ' col-12 col-md-6 col-lg-4" data-item="' + index + '" data-document-type="' + mainCategory + '">';
                  template += '<div class="container-card position-relative">';
                  template += '<div class="categories d-flex flex-row justify-content-between">';
                  if (item.subCat1 != undefined) { template += '<div class="title-container text-capitalize h6 text-orange">' + item.subCat1 + '</div>'; }

                  if (item.officeDepartmentDisplayName != undefined) {
                    template += '<div class="card-option h6 text-uppercase">';
                    template += '<strong class="subCat1">';
                    template += item.officeDepartmentDisplayName;
                    template += '</strong>';
                    template += '</div>';
                  }
                  template += '</div>';

                  template += '<picture>';
                  template += '<source srcset="' + defaultImage + '?mode=crop&amp;width=350&amp;height=250 1x, ' + defaultImage + '?mode=crop&amp;width=700&amp;height=500 2x" media="all">';
                  template += '<img class="position-absolute" src="' + defaultImage + '?mode=crop&amp;width=400&amp;height=300" alt="default filter documents ' + index + '">';
                  template += '</picture>';

                  template += '<div class="content">';
                  template += '<div class="title h5">';
                  template += item.title;
                  template += '</div>';

                  template += '<div class="date h6">';
                  template += item.date;
                  template += '</div>';

                  if (item.description != undefined) {
                    template += '<div class="description">';
                    template += item.description;
                    template += '</div>';
                  }
                  template += '</div>';

                  template += '<div class="cta">';
                  template += '<a class="link-arrow" href="' + cdnUrl + item.url + '" data-id="' + item.id + '" target="_blank">View Resource</a>';
                  template += '</div>';
                  template += '</div>';
                  template += '</div>';
                } else {
                  template += '<div class="result-item ' + classImage + ' col-12 col-md-6 col-lg-4" data-item="' + index + '" data-document-type="' + mainCategory + '">';
                  template += '<div class="container-card position-relative">';
                  template += '<div class="categories d-flex flex-row justify-content-between">';
                  if (item.subCat1 != undefined) { template += '<div class="title-container text-capitalize h6 text-orange">' + item.subCat1 + '</div>'; }

                  if (item.officeDepartmentDisplayName != undefined) {
                    template += '<div class="card-option h6 text-uppercase">';
                    template += '<strong class="subCat1">';
                    template += item.officeDepartmentDisplayName;
                    template += '</strong>';
                    template += '</div>';
                  }
                  template += '</div>';

                  template += '<div class="content">';
                  template += '<div class="title h5">';
                  template += item.title;
                  template += '</div>';

                  template += '<div class="date h6">';
                  template += item.date;
                  template += '</div>';

                  if (item.description != undefined) {
                    template += '<div class="description">';
                    template += item.description;
                    template += '</div>';
                  }
                  template += '</div>';

                  template += '<div class="cta">';
                  template += '<a class="link-arrow" href="' + cdnUrl + item.url + '" data-id="' + item.id + '" target="_blank">View Resource</a>';
                  template += '</div>';
                  template += '</div>';
                  template += '</div>';
                }
              }
              break;
          }
          break;
      }
    });

    switch (typeOfDocument) {
      case "video":
        $(facetedFilter).find('.facets-results .results').append(template);

        $('.faceted-filter-block[data-document="video"]').each(function(index, value) {
          $(value).find('a.video-selector-iframe').colorbox(cboxOptions);
        });
        break;
      case "Documents":
        $(facetedFilter).find('.facets-results .results').append(template);
        updateFilters(facetedFilter, FacetsOfficeDept, FacetsDocType, FacetsSubjects);
        break;
      case "job":
        $(facetedFilter).find('.facets-results .results').append('<div class="col-12 content"><div class="main-container"><div class="link-items"></div></div></div>');
        $(facetedFilter).find('.facets-results .results .content .main-container .link-items').append(template);
        break;
      default:
        $(facetedFilter).find('.facets-results .results').append(template);
        break;
    }

    if (items.length < 1) {
      $(facetedFilter).find('.facets-results .results').append('<div class="wysiwyg-field"><h2>No results found.</h2></div>');
    }

    $(facetedFilter).find('.facets-results').removeClass('d-none');

    if (pages >= 1) {
      $(facetedFilter).find('.facets-results .global-search-pagination').removeClass('d-none');
      renderPagination(pages, currentPage, maxElements, items, $(facetedFilter).find('.facets-results .global-search-pagination'));
    } else {
      $(facetedFilter).find('.facets-results .global-search-pagination').addClass('d-none');
    }
  }

  $(document).on('submit', '#search-facets', function(e) {
    if ($(this).closest('.faceted-filter-block').hasClass('use-ajax')) {
      e.preventDefault();
      window.history.pushState({}, null, replaceUrlParam(window.location.href, 'query', urlEncode($(this).find('input').val())));

      if ($(this).find('input').val() == '') {
        window.history.pushState({}, null, removeURLParameter(window.location.href, 'query'));
      }

      window.history.pushState({}, null, removeURLParameter(window.location.href, 'page'));
      const facetedFilter = $(this).closest('.faceted-filter-block');
      const typeOfDocument = $(this).closest('.faceted-filter-block').attr('data-document');
      const mainCategory = urlEncode($(this).closest('.faceted-filter-block').attr('data-main-category'));
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const query = urlParams.get('query') ?? '';
      const sort = urlParams.get('sort') ?? 'desc';
      const officeDept = urlParams.get('officeDept') ?? '';
      const docType = urlParams.get('docType') ?? '';
      const subject = urlParams.get('subject') ?? '';
      const dateStart = urlParams.get('dateStart') ?? '';
      const dateEnd = urlParams.get('dateEnd') ?? '';
      window.history.pushState({}, null, removeURLParameter(window.location.href, 'page'));
      const page = urlParams.get('page') ?? 0;
      const letter = urlParams.get('letter') ?? '';

      let cached_data = window.cached_data['/umbraco/Api/FacetedFilter/GexMixedContent?documentType=' + typeOfDocument
        + '&mainCategory=' + mainCategory
        + '&letter=' + letter
        + '&query=' + query
        + '&officeDept=' + officeDept
        + '&docType=' + docType
        + '&subject=' + subject
        + '&dateStart=' + dateStart
        + '&dateEnd=' + dateEnd
        + '&page=' + page
        + '&sort=' + sort];

      if (cached_data) {
        renderContent(cached_data, facetedFilter, typeOfDocument, mainCategory);
        return;
      }

      getData('/umbraco/Api/FacetedFilter/GexMixedContent?documentType=' + typeOfDocument
        + '&mainCategory=' + mainCategory
        + '&letter=' + letter
        + '&query=' + query
        + '&officeDept=' + officeDept
        + '&docType=' + docType
        + '&subject=' + subject
        + '&dateStart=' + dateStart
        + '&dateEnd=' + dateEnd
        + '&page=' + page
        + '&sort=' + sort).then(function(res) {
          window.cached_data['/umbraco/Api/FacetedFilter/GexMixedContent?documentType=' + typeOfDocument
            + '&mainCategory=' + mainCategory
            + '&letter=' + letter
            + '&query=' + query
            + '&officeDept=' + officeDept
            + '&docType=' + docType
            + '&subject=' + subject
            + '&dateStart=' + dateStart
            + '&dateEnd=' + dateEnd
            + '&page=' + page
            + '&sort=' + sort] = res;
          renderContent(res, facetedFilter, typeOfDocument, mainCategory);
        });
    }
  });

  $(document).on('click', '.faceted-filter-block .char-filters-container button', function(e) {
    $(this).closest('.char-filters-container').find('button').removeClass('active');
    $(this).addClass('active');

    if ($(this).closest('.faceted-filter-block').hasClass('use-ajax')) {
      e.preventDefault();
      window.history.pushState({}, null, removeURLParameter(window.location.href, 'page'));
      window.history.pushState({}, null, replaceUrlParam(window.location.href, 'letter', $(this).attr('data-value')));
      const facetedFilter = $(this).closest('.faceted-filter-block');
      const typeOfDocument = $(this).closest('.faceted-filter-block').attr('data-document');
      const mainCategory = urlEncode($(this).closest('.faceted-filter-block').attr('data-main-category'));
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      var query = urlParams.get('query');
      const sort = urlParams.get('sort') ?? 'desc';
      const officeDept = urlParams.get('officeDept');
      const docType = urlParams.get('docType');
      const subject = urlParams.get('subject');
      const dateStart = urlParams.get('dateStart');
      const dateEnd = urlParams.get('dateEnd');
      const page = urlParams.get('page');
      const letter = urlParams.get('letter');

      var queryField = $(facetedFilter).find('input[name=query]');
      if (queryField !== undefined) {
        query = $(queryField).val();
      }

      console.log(query);

      getData('/umbraco/Api/FacetedFilter/GexMixedContent?documentType=' + typeOfDocument
        + '&mainCategory=' + mainCategory
        + '&letter=' + letter
        + '&query=' + query
        + '&officeDept=' + officeDept
        + '&docType=' + docType
        + '&subject=' + subject
        + '&dateStart=' + dateStart
        + '&dateEnd=' + dateEnd
        + '&page=' + page
        + '&sort=' + sort).then(function(res) {
          renderContent(res, facetedFilter, typeOfDocument, mainCategory);
        });
    } else {
      var newUrl = updateQueryStringParameter(window.location.href, 'letter', $(this).attr('data-value'));
      window.location = newUrl;
    }
  });

  $(document).on('click', '.faceted-filter-block .reset-filters button', function(e) {
    $(this).closest('.form').find('input').val('');
    $(this).closest('.form').find('.char-filters-container').find('button').removeClass('active');
    window.history.pushState({}, null, getCleanUrl(window.location.href));

    if ($(this).closest('.faceted-filter-block').hasClass('use-ajax')) {
      e.preventDefault();
      const facetedFilter = $(this).closest('.faceted-filter-block');
      const typeOfDocument = $(this).closest('.faceted-filter-block').attr('data-document');
      const mainCategory = urlEncode($(this).closest('.faceted-filter-block').attr('data-main-category'));
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const query = urlParams.get('query');
      const sort = urlParams.get('sort') ?? 'desc';
      const officeDept = urlParams.get('officeDept');
      const docType = urlParams.get('docType');
      const subject = urlParams.get('subject');
      const dateStart = urlParams.get('dateStart');
      const dateEnd = urlParams.get('dateEnd');
      const page = urlParams.get('page');
      const letter = urlParams.get('letter');

      getData('/umbraco/Api/FacetedFilter/GexMixedContent?documentType=' + typeOfDocument
        + '&mainCategory=' + mainCategory
        + '&letter=' + letter
        + '&query=' + query
        + '&officeDept=' + officeDept
        + '&docType=' + docType
        + '&subject=' + subject
        + '&dateStart=' + dateStart
        + '&dateEnd=' + dateEnd
        + '&page=' + page
        + '&sort=' + sort).then(function(res) {
          renderContent(res, facetedFilter, typeOfDocument, mainCategory);
        });
    } else {
      window.location = window.location.href;
    }
  });

  $(document).on('click', '.job-search-container .nav-item .nav-link', function(e) {
    var newUrl = updateQueryStringParameter(window.location.href, 'letter', $(this).attr('data-value'));
    window.location = newUrl;
  });

  $(document).on('click', '.faceted-filter-block .filters-section .tab-content .tab-pane .filter-item .filter-button', function() {
    if ($(this).hasClass('any')) {
      if ($(this).closest('.filter-item').hasClass('active')) {
        $(this).closest('.filter-item').removeClass('active');
      } else {
        $(this).closest('.tab-pane').find('.filter-item').removeClass('active');
        $(this).closest('.tab-pane').find('.filter-item .filter-button input').removeAttr('checked');
        $(this).closest('.filter-item').addClass('active');
      }
    } else {
      $(this).closest('.filter-item').toggleClass('active');

      if ($(this).closest('.filter-item').hasClass('active')) {
        $(this).find('input').attr('checked', 'checked');
      } else {
        $(this).find('input').removeAttr('checked');
      }

      detectSelectedElements($(this).closest('.tab-content'), $(this).closest('.tab-pane'));
    }
  });

  $(document).on('click', '.faceted-filter-block .filters-section .filter-actions .apply-filters', function() {
    window.history.pushState({}, null, removeURLParameter(window.location.href, 'page'));
    var officeDept = $(this).closest('.faceted-filter-block').find('.filters-section .tab-content .tab-pane[data-tab="subcat1"] .filter-item.active');
    var docType = $(this).closest('.faceted-filter-block').find('.filters-section .tab-content .tab-pane[data-tab="subcat2"] .filter-item.active');
    var subjects = $(this).closest('.faceted-filter-block').find('.filters-section .tab-content .tab-pane[data-tab="subcat3"] .filter-item.active');
    var dates = $(this).closest('.faceted-filter-block').find('.filters-section .tab-content .tab-pane[data-tab="dates"]');
    var facetsOfficeDept = [];
    var facetsDocType = [];
    var facetsSubjects = [];
    var urlParameter;
    var dateStart = '';
    var dateEnd = '';

    if ($(officeDept).length > 0 && !($(officeDept).find('.filter-button').hasClass('any'))) {
      var officeDepartmentQuery = '(';
      $(officeDept).each(function(index) {
        facetsOfficeDept.push($(this).find('input').val());
        if (index == officeDept.length - 1) {
          officeDepartmentQuery += $(this).find('input').val() + ')';
        } else {
          officeDepartmentQuery += $(this).find('input').val() + '||';
        }
      });

      window.history.pushState({}, null, replaceUrlParam(window.location.href, 'officeDept', urlEncode(officeDepartmentQuery)));
    } else {
      window.history.pushState({}, null, removeURLParameter(window.location.href, 'officeDept'));
    }

    if ($(docType).length > 0 && !($(docType).find('.filter-button').hasClass('any'))) {
      var documentTypeQuery = '(';
      $(docType).each(function(index) {
        facetsDocType.push($(this).find('input').val());
        if (index == docType.length - 1) {
          documentTypeQuery += $(this).find('input').val() + ')';
        } else {
          documentTypeQuery += $(this).find('input').val() + '||';
        }
      });

      window.history.pushState({}, null, replaceUrlParam(window.location.href, 'docType', urlEncode(documentTypeQuery)));
    } else {
      window.history.pushState({}, null, removeURLParameter(window.location.href, 'docType'));
    }

    if ($(subjects).length > 0 && !($(subjects).find('.filter-button').hasClass('any'))) {
      var subjectsQuery = '(';
      $(subjects).each(function(index) {
        facetsSubjects.push($(this).find('input').val());
        if (index == subjects.length - 1) {
          subjectsQuery += $(this).find('input').val() + ')';
        } else {
          subjectsQuery += $(this).find('input').val() + '||';
        }
      });

      window.history.pushState({}, null, replaceUrlParam(window.location.href, 'subject', urlEncode(subjectsQuery)));
    } else {
      window.history.pushState({}, null, removeURLParameter(window.location.href, 'subject'));
    }

    if ($(dates).length > 0) {
      var dateLeft = $(dates).find('.date-left').val();
      var _dateLeft = new Date(dateLeft);
      dateLeft = String(_dateLeft.getDate()).padStart(2, '0') + '/' + String(_dateLeft.getMonth() + 1).padStart(2, '0') + '/' + _dateLeft.getFullYear();

      var dateRight = $(dates).find('.date-right').val();
      var _dateRight = new Date(dateRight);
      dateRight = String(_dateRight.getDate()).padStart(2, '0') + '/' + String(_dateRight.getMonth() + 1).padStart(2, '0') + '/' + _dateRight.getFullYear();

      if (dateLeft !== '' && dateRight !== '') {
        window.history.pushState({}, null, replaceUrlParam(window.location.href, 'dateStart', urlEncode(dateLeft)));
        window.history.pushState({}, null, replaceUrlParam(window.location.href, 'dateEnd', urlEncode(dateRight)));
      } else {
        window.history.pushState({}, null, removeURLParameter(window.location.href, 'dateStart'));
        window.history.pushState({}, null, removeURLParameter(window.location.href, 'dateEnd'));
      }
    }


    if ($(this).closest('.faceted-filter-block').hasClass('use-ajax')) {
      const facetedFilter = $(this).closest('.faceted-filter-block');
      const typeOfDocument = $(this).closest('.faceted-filter-block').attr('data-document');
      const mainCategory = urlEncode($(this).closest('.faceted-filter-block').attr('data-main-category'));
      window.history.pushState({}, null, replaceUrlParam(window.location.href, 'query', urlEncode($(this).closest('.faceted-filter-block').find('.form input').val())));

      if ($(this).closest('.faceted-filter-block').find('.form input').val() == '') {
        window.history.pushState({}, null, removeURLParameter(window.location.href, 'query'));
      }
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const query = urlParams.get('query');
      const sort = urlParams.get('sort') ?? 'desc';
      const officeDept = urlParams.get('officeDept');
      const docType = urlParams.get('docType');
      const subject = urlParams.get('subject');
      const dateStart = urlParams.get('dateStart');
      const dateEnd = urlParams.get('dateEnd');
      const page = urlParams.get('page');
      const letter = urlParams.get('letter');

      getData('/umbraco/Api/FacetedFilter/GexMixedContent?documentType=' + typeOfDocument
        + '&mainCategory=' + mainCategory
        + '&letter=' + letter
        + '&query=' + query
        + '&officeDept=' + officeDept
        + '&docType=' + docType
        + '&subject=' + subject
        + '&dateStart=' + dateStart
        + '&dateEnd=' + dateEnd
        + '&page=' + page
        + '&sort=' + sort).then(function(res) {
          renderContent(res, facetedFilter, typeOfDocument, mainCategory);
        });
    } else {
      window.location.href = window.location.href;
    }
  });

  $(document).on('click', '.faceted-filter-block .filters-section .filter-actions .clear-filters', function(e) {
    if ($(this).closest('.faceted-filter-block').hasClass('use-ajax')) {
      e.preventDefault();
      window.history.pushState({}, null, removeURLParameter(window.location.href, 'query'));
      window.history.pushState({}, null, getCleanUrl(window.location.href));
      $(this).closest('.faceted-filter-block').find('.tab-pane[data-tab="dates"] .datetimepicker-input.date-left').val('01/01/1928');
      $(this).closest('.faceted-filter-block').find('.tab-pane[data-tab="dates"] .datetimepicker-input.date-right').val('12/11/2050');
      $(this).closest('.filters-section').removeClass('has-filters');
      $(this).closest('.filters-section').find('.tab-content .filter-item').removeClass('active');
      $(this).closest('.filters-section').find('.tab-content .filter-item .filter-button input').removeAttr('checked');
      $(this).closest('.faceted-filter-block').find('.form form input').val('');
      $(this).closest('.faceted-filter-block').find('.facets-results').removeClass('important-show');
      const facetedFilter = $(this).closest('.faceted-filter-block');
      const typeOfDocument = $(this).closest('.faceted-filter-block').attr('data-document');
      const mainCategory = urlEncode($(this).closest('.faceted-filter-block').attr('data-main-category'));
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const query = urlParams.get('query');
      const sort = urlParams.get('sort') ?? 'desc';
      const officeDept = urlParams.get('officeDept');
      const docType = urlParams.get('docType');
      const subject = urlParams.get('subject');
      const dateStart = urlParams.get('dateStart');
      const dateEnd = urlParams.get('dateEnd');
      const page = urlParams.get('page');
      const letter = urlParams.get('letter');

      getData('/umbraco/Api/FacetedFilter/GexMixedContent?documentType=' + typeOfDocument
        + '&mainCategory=' + mainCategory
        + '&letter=' + letter
        + '&query=' + query
        + '&officeDept=' + officeDept
        + '&docType=' + docType
        + '&subject=' + subject
        + '&dateStart=' + dateStart
        + '&dateEnd=' + dateEnd
        + '&page=' + page
        + '&sort=' + sort).then(function(res) {
          renderContent(res, facetedFilter, typeOfDocument, mainCategory);
        });
    }
  });

  function renderPagination(pages, currentPage, data, maxElements, html) {
    var _totalPages = 0;
    var prevPage = "disabled";
    var nextPage = "disabled";
    var initialPage = 0;
    var lastPage = 7;
    var items = "";
    currentPage = currentPage - 1;
    var pageSize = parseInt($(html).closest('.faceted-filter-block').attr('data-page-size'), 10);
    $(html).find('ul li').remove();

    if (data > pageSize) {
      _totalPages = pages;
      initialPage = (currentPage + 1) - 3;
      lastPage = (currentPage + 1) + 3;
      initialPage = initialPage < 1 ? 1 : initialPage;
      lastPage = lastPage > _totalPages ? _totalPages : lastPage;
      var firstRender = false;
      var index = 0;

      for (var i = initialPage; i <= lastPage; i++) {
        var liClass = "";
        if (i == (currentPage + 1)) {
          liClass = "active";
        }

        if (i == initialPage) {
          var previousPage = currentPage > 1 ? currentPage : currentPage;
          prevPage = (currentPage + 1) > 1 ? "" : "disabled";

          items += '<li class="paginationjs-prev ' + prevPage + '">';
          items += '<button class="' + prevPage + '" data-href="' + previousPage + '">';
          items += '<span class="material-icons notranslate">';
          items += 'keyboard_arrow_left';
          items += '</span>';
          items += '</button>';
          items += '</li>';
        }

        if ((currentPage + 1) > 2 && !firstRender) {
          items += '<li class="paginationjs-page J-paginationjs-page">';
          items += '<button data-href="' + 1 + '">';
          items += 1;
          items += '</button>';
          items += '</li>';

          items += '<li class="paginationjs-ellipsis disabled"><a>...</a></li>';

          firstRender = true;
        }

        if (index > 0 && index < 6) {
          items += '<li class="paginationjs-page paginationjs-page ' + liClass + '">';
          items += '<button data-href="' + i + '">';
          items += i;
          items += '</button>';
          items += '</li>';
        } else {
          if ((currentPage + 1) <= 2) {
            items += '<li class="paginationjs-page paginationjs-page ' + liClass + '">';
            items += '<button data-href="' + 1 + '">';
            items += 1;
            items += '</button>';
            items += '</li>';
          }
        }

        index++;
      }

      if (_totalPages > currentPage) {
        nextPage = "";
      }

      var _lastPage = (currentPage + 2);
      nextPage = _totalPages < (currentPage + 2) ? "disabled" : "";

      if (_totalPages - (currentPage + 1) > 5) {
        items += '<li class="paginationjs-ellipsis disabled"><a>...</a></li>';

        items += '<li class="paginationjs-page J-paginationjs-page">';
        items += '<button data-href="' + _totalPages + '">';
        items += _totalPages;
        items += '</button>';
        items += '</li>';
      }

      items += '<li class="paginationjs-next J-paginationjs-next ' + nextPage + '" title="Next page">';
      items += '<button class="' + nextPage + '" data-href="' + _lastPage + '">';
      items += '<span class="material-icons notranslate">';
      items += 'keyboard_arrow_right';
      items += '</span>';
      items += '</button>';
      items += '</li>';
    }

    $(html).find('ul').append(items);
  }

  function updateFilters(parentHtml, FacetsOfficeDept, FacetsDocType, FacetsSubjects) {
    var items = '';
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const officeDept = urlParams.get('officeDept');
    const docType = urlParams.get('docType');
    const subject = urlParams.get('subject');
    const dateStart = urlParams.get('dateStart');
    const dateEnd = urlParams.get('dateEnd');

    if (FacetsOfficeDept.length > 0) {
      items = '';
      $(parentHtml).find('.filters-section .tab-content .tab-pane[data-tab="subcat1"] .filter-item').remove();

      if (officeDept != null) {
        $(parentHtml).find('.filters-section .tab-content .tab-pane[data-tab="subcat1"] .row').append('<div class="filter-item col-12 col-md-6 col-lg-4 col-xl-3"><button class="filter-button any w-100 position-relative"><span class="filter-name">Any</span><span class="material-icons check-icon position-absolute">check_circle</span></button></div>');
      } else {
        $(parentHtml).find('.filters-section .tab-content .tab-pane[data-tab="subcat1"] .row').append('<div class="filter-item col-12 col-md-6 col-lg-4 col-xl-3 active"><button class="filter-button any w-100 position-relative"><span class="filter-name">Any</span><span class="material-icons check-icon position-absolute">check_circle</span></button></div>');
      }

      FacetsOfficeDept.forEach(function(item, index) {
        var displayName = item;
        switch (item) {
          case 'Real Property   Filming':
            displayName = 'Real Property & Filming';
            break;

          default:
            displayName = displayName;
            break;
        }

        if (officeDept != null) {
          var success = officeDept.indexOf(item) != -1;

          if (success) {
            items += '<div class="filter-item col-12 col-md-6 col-lg-4 col-xl-3 active">';
            items += '<button class="filter-button w-100 position-relative">';
            items += '<span class="filter-name">' + displayName + '</span>';
            items += '<span class="material-icons check-icon position-absolute">check_circle</span>';
            items += '<label class="d-none">' + item + '</label>';
            items += '<input class="d-none" type="checkbox" name="facet_subcat2" value="' + item + '" aria-label="' + item + '">';
            items += '</button>';
            items += '</div>';
          } else {
            items += '<div class="filter-item col-12 col-md-6 col-lg-4 col-xl-3">';
            items += '<button class="filter-button w-100 position-relative">';
            items += '<span class="filter-name">' + displayName + '</span>';
            items += '<span class="material-icons check-icon position-absolute">check_circle</span>';
            items += '<label class="d-none">' + item + '</label>';
            items += '<input class="d-none" type="checkbox" name="facet_subcat2" value="' + item + '" aria-label="' + item + '">';
            items += '</button>';
            items += '</div>';
          }
        } else {
          items += '<div class="filter-item col-12 col-md-6 col-lg-4 col-xl-3">';
          items += '<button class="filter-button w-100 position-relative">';
          items += '<span class="filter-name">' + displayName + '</span>';
          items += '<span class="material-icons check-icon position-absolute">check_circle</span>';
          items += '<label class="d-none">' + item + '</label>';
          items += '<input class="d-none" type="checkbox" name="facet_subcat2" value="' + item + '" aria-label="' + item + '">';
          items += '</button>';
          items += '</div>';
        }
      });

      $(parentHtml).find('.filters-section .tab-content .tab-pane[data-tab="subcat1"] .row').append(items);
    }

    if (FacetsDocType.length > 0) {
      items = '';
      $(parentHtml).find('.filters-section .tab-content .tab-pane[data-tab="subcat2"] .filter-item').remove();

      if (docType != null) {
        $(parentHtml).find('.filters-section .tab-content .tab-pane[data-tab="subcat2"] .row').append('<div class="filter-item col-12 col-md-6 col-lg-4 col-xl-3"><button class="filter-button any w-100 position-relative"><span class="filter-name">Any</span><span class="material-icons check-icon position-absolute">check_circle</span></button></div>');
      } else {
        $(parentHtml).find('.filters-section .tab-content .tab-pane[data-tab="subcat2"] .row').append('<div class="filter-item col-12 col-md-6 col-lg-4 col-xl-3 active"><button class="filter-button any w-100 position-relative"><span class="filter-name">Any</span><span class="material-icons check-icon position-absolute">check_circle</span></button></div>');
      }

      FacetsDocType.forEach(function(item, index) {
        if (docType != null) {
          var success = docType.indexOf(item) != -1;

          if (success) {
            items += '<div class="filter-item col-12 col-md-6 col-lg-4 col-xl-3 active">';
            items += '<button class="filter-button w-100 position-relative">';
            items += '<span class="filter-name">' + item + '</span>';
            items += '<span class="material-icons check-icon position-absolute">check_circle</span>';
            items += '<label class="d-none">' + item + '</label>';
            items += '<input class="d-none" type="checkbox" name="facet_subcat2" value="' + item + '" aria-label="' + item + '">';
            items += '</button>';
            items += '</div>';
          } else {
            items += '<div class="filter-item col-12 col-md-6 col-lg-4 col-xl-3">';
            items += '<button class="filter-button w-100 position-relative">';
            items += '<span class="filter-name">' + item + '</span>';
            items += '<span class="material-icons check-icon position-absolute">check_circle</span>';
            items += '<label class="d-none">' + item + '</label>';
            items += '<input class="d-none" type="checkbox" name="facet_subcat2" value="' + item + '" aria-label="' + item + '">';
            items += '</button>';
            items += '</div>';
          }
        } else {
          items += '<div class="filter-item col-12 col-md-6 col-lg-4 col-xl-3">';
          items += '<button class="filter-button w-100 position-relative">';
          items += '<span class="filter-name">' + item + '</span>';
          items += '<span class="material-icons check-icon position-absolute">check_circle</span>';
          items += '<label class="d-none">' + item + '</label>';
          items += '<input class="d-none" type="checkbox" name="facet_subcat2" value="' + item + '" aria-label="' + item + '">';
          items += '</button>';
          items += '</div>';
        }
      });

      $(parentHtml).find('.filters-section .tab-content .tab-pane[data-tab="subcat2"] .row').append(items);
    }

    if (FacetsSubjects.length > 0) {
      items = '';
      $(parentHtml).find('.filters-section .tab-content .tab-pane[data-tab="subcat3"] .filter-item').remove();

      if (subject != null) {
        $(parentHtml).find('.filters-section .tab-content .tab-pane[data-tab="subcat3"] .row').append('<div class="filter-item col-12 col-md-6 col-lg-4 col-xl-3"><button class="filter-button any w-100 position-relative"><span class="filter-name">Any</span><span class="material-icons check-icon position-absolute">check_circle</span></button></div>');
      } else {
        $(parentHtml).find('.filters-section .tab-content .tab-pane[data-tab="subcat3"] .row').append('<div class="filter-item col-12 col-md-6 col-lg-4 col-xl-3 active"><button class="filter-button any w-100 position-relative"><span class="filter-name">Any</span><span class="material-icons check-icon position-absolute">check_circle</span></button></div>');
      }

      FacetsSubjects.forEach(function(item, index) {
        if (subject != null) {
          var success = subject.indexOf(item) != -1;

          if (success) {
            items += '<div class="filter-item col-12 col-md-6 col-lg-4 col-xl-3 active">';
            items += '<button class="filter-button w-100 position-relative">';
            items += '<span class="filter-name">' + item + '</span>';
            items += '<span class="material-icons check-icon position-absolute">check_circle</span>';
            items += '<label class="d-none">' + item + '</label>';
            items += '<input class="d-none" type="checkbox" name="facet_subcat2" value="' + item + '" aria-label="' + item + '">';
            items += '</button>';
            items += '</div>';
          } else {
            items += '<div class="filter-item col-12 col-md-6 col-lg-4 col-xl-3">';
            items += '<button class="filter-button w-100 position-relative">';
            items += '<span class="filter-name">' + item + '</span>';
            items += '<span class="material-icons check-icon position-absolute">check_circle</span>';
            items += '<label class="d-none">' + item + '</label>';
            items += '<input class="d-none" type="checkbox" name="facet_subcat2" value="' + item + '" aria-label="' + item + '">';
            items += '</button>';
            items += '</div>';
          }
        } else {
          items += '<div class="filter-item col-12 col-md-6 col-lg-4 col-xl-3">';
          items += '<button class="filter-button w-100 position-relative">';
          items += '<span class="filter-name">' + item + '</span>';
          items += '<span class="material-icons check-icon position-absolute">check_circle</span>';
          items += '<label class="d-none">' + item + '</label>';
          items += '<input class="d-none" type="checkbox" name="facet_subcat2" value="' + item + '" aria-label="' + item + '">';
          items += '</button>';
          items += '</div>';
        }
      });

      $(parentHtml).find('.filters-section .tab-content .tab-pane[data-tab="subcat3"] .row').append(items);
    }
  }

  function getCleanUrl(url) {
    return url.replace(/#.*$/, '').replace(/\?.*$/, '');
  };

  function urlEncode(param) {
    return encodeURIComponent(param);
  }

  function removeURLParameter(url, parameter) {
    //prefer to use l.search if you have a location/link object
    var urlparts = url.split('?');
    if (urlparts.length >= 2) {

      var prefix = encodeURIComponent(parameter) + '=';
      var pars = urlparts[1].split(/[&;]/g);

      //reverse iteration as may be destructive
      for (var i = pars.length; i-- > 0;) {
        //idiom for string.startsWith
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1);
        }
      }

      return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
  }

  function replaceUrlParam(url, paramName, paramValue) {
    if (paramValue == null) {
      paramValue = '1';
    }
    var pattern = new RegExp('\\b(' + paramName + '=).*?(&|#|$)');
    if (url.search(pattern) >= 0) {
      return url.replace(pattern, '$1' + paramValue + '$2');
    }
    url = url.replace(/[?#]$/, '');
    return url + (url.indexOf('?') > 0 ? '&' : '?') + paramName + '=' + paramValue;
  }

  function detectSelectedElements(parent, childPanel) {
    var flag = false;
    $(parent).find('.filter-item').each(function(indexButton, button) {
      flag = flag || $(button).hasClass('active');
    });

    flag = flag || $(parent).closest('.faceted-filter-block').find('.form form input[name="q"]').val() != '';

    if (flag) {
      $(parent).closest('.filters-section').addClass('has-filters');
      $(childPanel).find('.filter-item .any').parent().removeClass('active');
    } else {
      $(parent).closest('.filters-section').removeClass('has-filters');
      $(childPanel).find('.filter-item .any').parent().addClass('active');
    }
  }

  /*
   * Event click to set a new page for pagination.
   */
  $(document).on('click', '.faceted-filter-block .switch-filters-block .container-switcher-button .open-filters', function() {
    $(this).closest('.switch-filters-block').toggleClass('opened');
    $(this).closest('.faceted-filter-block').find('.filters-section').toggleClass('d-none');
  });

  $(document).on('click', '.faceted-filter-block .facets-results .choose-view .btn-group button', function() {
    $(this).closest('.btn-group').find('button').removeClass('active');
    $(this).addClass('active');

    if ($(this).hasClass('grid')) {
      $(this).closest('.facets-results').find('.result-item').removeClass('col-12 hide-image');
      $(this).closest('.facets-results').find('.result-item').addClass('col-12 col-md-6 col-lg-4');
      $(this).closest('.facets-results').removeClass('one-column');
      $(this).closest('.facets-results').addClass('grids');
    } else {
      $(this).closest('.facets-results').find('.result-item').removeClass('col-12 col-md-6 col-lg-4');
      $(this).closest('.facets-results').find('.result-item').addClass('col-12 hide-image');
      $(this).closest('.facets-results').addClass('one-column');
      $(this).closest('.facets-results').removeClass('grids');
    }
  });
})(jQuery);
